.project-summary {
  height: 100vh;
  width: 100%;
  max-height: 1080px;
  padding-bottom: var(--spaceL);
  margin: 120px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  @media (--mediaDesktop) {
    & {
      margin: 40px 0;
    }
  }

  @media (--mediaTablet) {
    & {
      height: auto;
      margin: 60px 0;
    }
  }

  @media (--mediaMobile) {
    & {
      padding-bottom: var(--space4XL);
      margin-bottom: 0;
    }
  }
}

.project-summary--first {
  margin-top: 0;

  @media (--mediaTablet) {
    & {
      margin-top: 0;
    }
  }
}

.project-summary__content {
  width: 100%;
  max-width: var(--maxWidthL);
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: var(--space2XL);

  @nest .project-summary--alternate & {
    grid-template-columns: 1fr 40%;
  }

  @media (--mediaLaptop) {
    .project-summary__content {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (--mediaTablet) {
    @nest .project-summary--alternate & {
      grid-template-columns: 100%;
    }
  }

  @media (--mediaTablet) {
    & {
      grid-template-columns: 100%;
      flex-direction: column-reverse;
      height: auto;
    }
  }
}

.project-summary__details {
  z-index: 1;
  position: relative;

  @media (--mediaTablet) {
    & {
      flex: 0 0 auto;
      max-width: 410px;
      grid-row: 2;
      grid-column: 1;
      justify-self: center;
    }
  }
}

.project-summary__preview {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.project-summary__model {
  --width: 180%;
  --aspectRatio: 10 / 12;

  min-width: var(--width);
  padding-bottom: calc(var(--aspectRatio) * var(--width));
}

.project-summary__model--laptop {
  top: 6%;
  left: 14%;

  @media (--mediaDesktop) {
    & {
      --width: 180%;
    }
  }

  @media (--mediaTablet) {
    & {
      --width: 140%;

      top: 10%;
      left: 0;
    }
  }
}

.project-summary__model--phone {
  --width: 116%;
  --aspectRatio: 10 / 7;

  top: 5%;
  left: -10%;

  @media (--mediaDesktop) {
    & {
      --width: 108%;

      left: 0;
    }
  }

  @media (--mediaTablet) {
    & {
      --width: 108%;

      left: 0;
    }
  }
}

.project-summary__svg {
  opacity: 0;
  transition: opacity 1200ms ease 1400ms;
  fill: var(--colorTextTitle);
}

.project-summary__svg--entered {
  opacity: var(--opacity);
}

.project-summary__svg--laptop {
  position: absolute;
  width: 100%;
  right: -36%;
  bottom: 24%;
  transform: translateY(50%);

  @media (--mediaTablet) {
    & {
      width: 80%;
      right: -10%;
      bottom: 18%;
    }
  }

  @media (--mediaMobile) {
    & {
      right: 0;
    }
  }
}

.project-summary__svg--phone {
  position: absolute;
  bottom: 23%;
  transform: translateY(50%);
  width: 100%;
  transition-delay: 800ms;

  @media (--mediaTablet) {
    & {
      width: 80%;
      bottom: 23%;
    }
  }
}

.project-summary__index {
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: var(--spaceM);
  align-items: center;
  margin-bottom: var(--spaceXL);
}

.project-summary__index-number {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translateX(calc(var(--spaceM) * -1));
    }
  }
}

.project-summary__index-number--entered {
  transform: translateX(0);
  opacity: 1;
}

.project-summary__title {
  margin-bottom: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.project-summary__title--entered {
  transform: none;
  opacity: 1;
}

.project-summary__description {
  margin-bottom: var(--spaceXL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationL);
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.project-summary__description--entered {
  transform: none;
  opacity: 1;
}

.project-summary__button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationXL);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }

}

.project-summary__button--entered {
  transform: none;
  opacity: 1;

  
}




/* .contact__button {
  background: var(--colorTextTitle);
} */